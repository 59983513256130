import Decimal from 'decimal.js'
import { Image } from './Image'
export const TokenListItem = ({
    tokenAddress,
    tokenSymbol,
    amountToTransfer,
}: {
    tokenAddress: string
    tokenSymbol: string
    amountToTransfer: Decimal
}) => {
    return (
        <div
            key={tokenAddress}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0.5rem',
                borderBottom: '1px solid #303030',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexGrow: 1,
                }}
            >
                <Image
                    size={32}
                    style={{ borderRadius: '50%', marginRight: '8px' }}
                    src={`/logos/${tokenSymbol.toLocaleLowerCase()}.svg`}
                    toAddress={tokenAddress}
                />

                <div>
                    <div
                        style={{
                            display: 'flex',
                        }}
                    >
                        {tokenSymbol} |{' '}
                        <span
                            style={{
                                flexGrow: 1,
                                marginLeft: '8px',
                            }}
                        >
                            {amountToTransfer.lessThan(new Decimal('0.0001')) ?  '<0.0001' : amountToTransfer.toJSON()}
                        </span>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                        }}
                    >
                        <div
                            style={{
                                color: '#929292',
                                fontSize: '12px',
                                lineHeight: '14px',
                                display: 'flex',
                                marginRight: '8px',
                            }}
                        >
                            {tokenAddress}
                        </div>
                        <img
                            src={'/external-link-muted.svg'}
                            width="12"
                            height="12"
                            style={{
                                cursor: 'pointer',
                            }}
                        />
                    </div>
                </div>
            </div>
            {/* {index === 0 ? (
                <ToolTip
                    title={
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                fontFamily: 'ProtoMono!important',
                            }}
                        >
                            This is a verified token address. This means that the token address has been verified by the
                            Safe team.
                        </div>
                    }
                >
                    <img
                        src="/verified.svg"
                        height="16"
                        width="16"
                        style={{
                            marginRight: '3px',
                            cursor: 'pointer',
                        }}
                    />
                </ToolTip>
            ) : (
                <ToolTip
                    title={
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                fontFamily: 'ProtoMono!important',
                            }}
                        >
                            This is an unverified token address. Please double check that is it correct before
                            interacting with it.
                        </div>
                    }
                >
                    <img
                        src="/warning.svg"
                        height="22"
                        width="22"
                        style={{
                            cursor: 'pointer',
                        }}
                    />
                </ToolTip>
            )} */}
        </div>
    )
}
